import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  private headerMenuSource = new BehaviorSubject<string[]>([]);
  headerMenu$ = this.headerMenuSource.asObservable();

  // Method to update the header menu items
  updateHeaderMenu(menuItems: string[]) {
    this.headerMenuSource.next(menuItems);
  }

}
