import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';

export abstract class BaseApiService<T> {
  constructor(protected ctrl: string, protected dataService: DataService) {
  }

  getById(id: string): Observable<T> {    
    return this.dataService.get<T>(environment.baseUrl + this.ctrl, id);
  }

  public getAll(): Observable<T[]> {
    return this.dataService.get<T[]>(environment.baseUrl + this.ctrl);
  }

  public save(data: T, id?: string): Observable<number> {
    if (id != null) {
      return this.dataService.put<number>(environment.baseUrl + this.ctrl + "/" + id, data);
    } else {
      return this.dataService.post<any>(environment.baseUrl + this.ctrl, data);
    }
  }
  public changeStatus(id: string, data?: T): Observable<void> {
    return this.dataService.patch<void>(environment.baseUrl + this.ctrl, id, data);
  }

  ImageUpload(formData:FormData,id): Observable<any> {
    return this.dataService.put<void>(environment.baseUrl + `users/updateProfile/` + id, formData,true);
    // return this.dataService.put(`users/updateProfile/${id}`, formData,true)
  }
}
