import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private httpClient: HttpClient) {
  }

  get<T>(url: string, id?: number | string | boolean, params?: any, headers?: any, responseType: 'json' | 'arraybuffer' = 'json'): Observable<T> {  
    const options: any = { 
      responseType: responseType // Ensure correct type
    };

    if (params) {
      options['params'] = params;
    }
    if (headers) {
      options['headers'] = headers;
    }

    if (id !== null && id !== undefined) {
      return this.httpClient.get<T>(`${url}/${id}`, options as { responseType: any });
    } else {
      return this.httpClient.get<T>(`${url}`, options as { responseType: any });
    }
}

  // get<T>(url: string, id?: number| string | boolean | any, params?: any, headers?: any): Observable<T> {  
  //   const options = {};
  //   if (params) {
  //     options['params'] = params;
  //   }
  //   if (headers) {
  //     options['headers'] = headers;
  //   }

  //   if (id !== null && id !== undefined) {
  //     return this.httpClient.get<T>(`${url}/${id}`, options);
  //   } else {
  //     return this.httpClient.get<T>(`${url}`, options);
  //   }
  // }

 
 
  post<T>(url: string, data: any, headers?: any): Observable<T> { 
    const options = {};
    if (headers) {
      options['headers'] = headers;
    }
    return this.httpClient.post<T>(`${url}`, data, options);
  }

  put<T>(url: string, data: any,headers?: any): Observable<T> {
    const options = {};
    if (headers) {
      options['headers'] = headers;
    }
    return this.httpClient.put<T>(`${url}`, data,options);
  }

  // delete<T>(url: string, id?: number | string): Observable<T> {
  //   return this.httpClient.delete<T>(`${url}/${id}`);
  // }

  delete<T>(url: string, id?: number | string): Observable<T> {
    return this.httpClient.delete<T>(`${url}`);
  }

  patch<T>(url: string, id: number | string, data?: any,headers?: any): Observable<T> {
    const options = {};
    if (headers) {
      options['headers'] = headers;
    }
    return this.httpClient.patch<T>(`${url}/${id}`, data,options);
  }

  postFile<T>(url: string, data: FormData): Observable<T> { 
    const headers = new HttpHeaders(); // Do not set Content-Type manually
  
    const options = {
      headers: headers,
      reportProgress: true, // Enable progress tracking if needed
      observe: 'events' as 'body', // Can be 'events' if you need progress updates
    };
  
    return this.httpClient.post<T>(url, data, options);
  }

}
