import { BaseApiService } from "src/app/core/services/base-api.service";
import { DataService } from "src/app/core/services/data.service";
import { UserModel } from "./users.model";
import { Injectable } from "@angular/core";
@Injectable({providedIn:'root'})

export class UserService extends BaseApiService<UserModel>{
    constructor(protected override dataService: DataService) {
      super('users', dataService);
     }
    
    }