import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {CountryModel, DropDownModel, StateModel } from '../models/drop-down.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {
  constructor(private data: DataService) { }

  private filterDeleted(data?: any[], idToInclude?: number): DropDownModel[] {
    if (!data || !data.length) {
      return data;
    }

    const filteredData = data.filter((t) => t.id === idToInclude || !t.isDeleted);
    return filteredData;
  }

  private getData(url: string, idToInclude?: any): Observable<DropDownModel[]> {
    return this.data.get<DropDownModel[]>(url).pipe(map((data) => this.filterDeleted(data)));
  }
  private getDataForAny(url: string, idToInclude?: any): Observable<any[]> {
    return this.data.get<any[]>(url).pipe(map((data) => this.filterDeleted(data)));
  }
  getBankNameDropDown(idToInclude?: any) {
    return this.getData(environment.baseUrl +'Bank/GetBankDropDown', idToInclude);
  }
  getappscreenDropDown() : Observable<any> {
    return this.getData(environment.baseUrl +`AppScreen/GetSubMenus`);
  }
  getAllAppScreenDropDown()  : Observable<any> {
    return this.getData(environment.baseUrl +`AppScreen/GetAllAppScreenDropDown`);
  }
  getAllTimezonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`common/timezones`);
  }
  getTimezonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`groups`);
  }
  getAllzonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`zones/getzonebyuser`);
  }
  getAllzones(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`zones`);
  }
  getAllRolesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`role`);
  } 
  getAllGatewayTypeDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`payment/gateway-type`);
  }
  getAllCheckListTypesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`checklist/checklisttypes`);
  }
  getAllDurationsDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`pricingPackages/durations`);
  }
  getAllgroupsDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`groups/getgroupbyuser`);
  }
  getAllgroups(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`groups`);
  }
  getAllParkingTypeDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`facilities/parking-type`);
  }
  getAllLicensePlateDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl + `license-plates`);
  }
  getAllTypeOfPermitDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl + `permit-type`);
  }
  getAllVehicleTypeDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl + `facilities/vehicle-type`);
  }
  getAllLocationsDropDown(): Observable<DropDownModel[]> {
    return this.getDataForAny(environment.baseUrl + `locations/getlocationbyuser`);
  }
  getAllLocations(): Observable<DropDownModel[]> {
    return this.getDataForAny(environment.baseUrl + `locations`);
  }
  getPermitTypePricingDetails(permitTypeIds?: string) {
    return this.getData(environment.permitApiBaseUrl +`permit-type/permit-type-pricing-info/${permitTypeIds}`);
  }
  getPermitTypeCategoriesDropDown(): Observable<DropDownModel[]> {    
    return this.getData(environment.permitApiBaseUrl +`common/PermitTypeCategories`);
  }
  getPermitDurationListDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`common/GetPermitDuration`);
  }
  getLiencencePlatesListDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`license-plates`);
  }
  getAcceptedDocumentsListDropDown(): Observable<any[]> {
     return this.getDataForAny(environment.permitApiBaseUrl +`documentCategory`);
  }
  getClassificationDropDown(): Observable<any[]> {
    return this.getDataForAny(environment.permitApiBaseUrl +`classifications`);
  }  
  getTimezones(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`common`);
  }
  getPriceByDurationId(durationId:string): Observable<DropDownModel[]> {    
    return this.getData(environment.permitApiBaseUrl +`common/GetByDurationId/${durationId}`);
  }
  getFileTypesListDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`acceptedDocuments/fileTypes`);
  }
  getAllFaqCategory(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`faq-category`);
  }
  getCountryListDropDown(): Observable<any[]> {
    return this.getDataForAny(environment.baseUrl +`common/countries`);
  }
  getStateListDropDown(countryCode:number): Observable<any[]> {
   return this.getDataForAny(environment.baseUrl +`common/states?CountryCode=${countryCode}`);
  }
  getPermitApplicationStatus(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`common/GetPermitApplicationStatus`);
  }
  getPermitStatus(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`common/getPermitStatus`);
  }
  getPaymentStatus(): Observable<DropDownModel[]> {
    return this.getData(environment.permitApiBaseUrl +`common/GetPaymentStatus`);
  }
  getAllViolationNumberingDropDown(): Observable<DropDownModel[]> {
    return this.getDataForAny(environment.enforcementApiBaseUrl + `violation-numbers`);
  } 
  GetVehicleMakeList():Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`vehicle-makes`);
  }
  GetVehiclColorList():Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`vehicle-colors`);
  }
  GetDocCategoryByPermit(permitTypeId?: string):Observable<DropDownModel[]>{
    return this.getData(environment.baseUrl +`permit/catalogue/${permitTypeId}/documentcategories`);
  }
  getAllLocationAuditHistoryLogsListDropDown(Id: string): Observable<any[]> {
    return this.getData(environment.baseUrl +`locations/location-audit-logs/${Id}`);
  }
  getAllViolationConfigurationDropDown(): Observable<any[]> {
    return this.getData(environment.enforcementApiBaseUrl + `violation-configurations`);
  }
  getAllViolationConfigurationTypeDropDown(): Observable<any[]> {
    return this.getData(environment.enforcementApiBaseUrl + `violation-type`);
  }
  getAllViolationConfigurationCostStructureDropDown(): Observable<any[]> {
    return this.getData(environment.enforcementApiBaseUrl + `coststructure`);
  }
  getAllUserListDropDown(): Observable<any[]> {
    return this.getData(environment.baseUrl + `users`);
  }
  getViolationDesignListDropDown(): Observable<any[]> {
    return this.getDataForAny(environment.enforcementApiBaseUrl +`violation-configurations`);
  }
  getViolationStatusDropDown(): Observable<any[]> {
    return this.getData(environment.enforcementApiBaseUrl +`violation-status`);
  }
  getArchivedlistDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`common/getArchivedlist`);
  }
  getAllStateslistDropDown(): Observable<any[]> {
    return this.getData(environment.baseUrl +`common/states`);
  }
  getAllLocationConfigurationlistDropDown(): Observable<any[]> {
    return this.getData(environment.enforcementApiBaseUrl +`location-configuration/addcost`);
  }
}
