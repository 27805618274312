import { DataService } from 'src/app/core/services/data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnumModel } from '../models/enum-model';

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  ctrlName = 'Enums';
  constructor(private data: DataService) {}

  getDateFormats(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/DateFormats`);
  }

  getTimeFormats(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/TimeFormats`);
  }

  getEditCheckValidations(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/EditCheckValidations`);
  }

  getComparisonList(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/ComparisonList`);
  }

  ComparisonWitohutCheckList(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/ComparisonWitohutCheckList`);
  }

  EditCheckRuleBy(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/EditCheckRuleBy`);
  }

  ProjectScheduleOperator(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/ProejctScheduleOperator`);
  }

  CodedType(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/CodedType`);
  }

  CommentStatus(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/CommentStatus`);
  }
  
  HolidayType(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/HolidayType`);
  }

  getGender(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/GetGender`);
  }
  getRelationship(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/getRelationship`);
  }

  getRefrenceType(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/GetRefrenceType`);
  }
  getActivityType(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/GetActivityType`);
  }

  getUploadlimitType(): Observable<EnumModel[]> {
    return this.data.get<EnumModel[]>(`${this.ctrlName}/GetUploadLimitType`);
  }


}
