import { BaseSettingsModel } from './../models/base-settings.model';
import { Injectable } from '@angular/core';
import { GeneralSettingsModel } from '../models/general-settings';
import { ScreenRightsModel } from '../models/screen-rights-model';
import { CurrentUserModel } from '../models/current-user';

const TOKEN_KEY = 'AuthToken';
const RIGHTS_KEY = 'Rights';
const GEN_SET_KEY = 'GeneralSettings';
const BASE_URL_KEY = 'baseUrl';
const BASE_AuthURL_KEY = 'baseAuthUrl';
const SIGNALR_URL_KEY = 'signalrUrl';
const REFRESH_TOKEN_KEY = 'refreshToken';
const CURRENT_USER_KEY = 'currentUser';
const AUDIT_REASON_ID = 'audit-reason-id';
const AUDIT_REASON_OTH = 'audit-reason-oth';
const AUDIT_REASON_NAME = 'audit-reason-name';
const LANGUAGE = 'Multilanguage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  private removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  private setItem(key: string, value: string) {
    this.removeItem(key);
    window.localStorage.setItem(key, value);
  }

  private getItem(key: string) {
    return window.localStorage.getItem(key);
  }

  public setToken(token: string) {
    if (!token) return;
    this.setItem(TOKEN_KEY, token);
  }

  private setRefreshToken(token: string) {
    if (!token) return;
    this.setItem(REFRESH_TOKEN_KEY, token);
  }

  private setRights(rights: ScreenRightsModel[]) {
    if (!rights) return;
    this.setItem(RIGHTS_KEY, JSON.stringify(rights));
  }

  private setGeneralSettings(settings: GeneralSettingsModel) {
    if (!settings) return;
    this.setItem(GEN_SET_KEY, JSON.stringify(settings));
  }

  private setBaseUrl(url: string) {
    this.setItem(BASE_URL_KEY, url);
  }

  private setAuthBaseUrl(url: string) {
    this.setItem(BASE_AuthURL_KEY, url);
  }

  private setSignalRUrl(url: string) {
    this.setItem(SIGNALR_URL_KEY, url);
  }

  private setCurrentUser(currentUser: CurrentUserModel) {
    if (!currentUser) return;
    this.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser));
  }

  setUserToken(data: CurrentUserModel)
  {
    this.setToken(data.token);
    this.setRefreshToken(data.refreshToken);
    this.setCurrentUser(data);
  }


  setUserDataWithRole(data: CurrentUserModel) {
    this.setRights(data.rights);
    delete data.rights;
    this.setCurrentUser(data);
  }


  setBaseSettings(settings: BaseSettingsModel) {
    this.setBaseUrl(settings.baseUrl);
    this.setAuthBaseUrl(settings.baseAuthUrl);
    this.setSignalRUrl(settings.signalrUrl);
  }

  get BaseUrl(): string {
    const item = this.getItem(BASE_URL_KEY);
    return item;
  }

  get BaseAuthUrl(): string {
    const item = this.getItem(BASE_AuthURL_KEY);
    return item;
  }

  get SignalRUrl(): string {
    const item = this.getItem(SIGNALR_URL_KEY);
    return item;
  }

  get Token(): string {
    const item = this.getItem(TOKEN_KEY);
    return item;
  }

  get RefreshToken(): string {
    const item = this.getItem(REFRESH_TOKEN_KEY);
    return item;
  }

  get HasToken(): boolean {
    return !!this.Token
  }

  get AuthHeader(): string {
    const token = this.Token;
    return token ? 'Bearer ' + token : '';
  }

  get Rights(): ScreenRightsModel[] {
    const stringValue = this.getItem(RIGHTS_KEY);
    const data = JSON.parse(stringValue) as ScreenRightsModel[];
    return data;
  }


  get GeneralSettings(): GeneralSettingsModel {
    const stringValue = this.getItem(GEN_SET_KEY);
    const data = JSON.parse(stringValue) as GeneralSettingsModel;
    return data;
  }

  get CurrentUser(): CurrentUserModel {
    const stringValue = this.getItem(CURRENT_USER_KEY);
    const data = JSON.parse(stringValue) as CurrentUserModel;
    return data;
  }

  get GetAuditReasonId(): string {
    const stringValue = this.getItem(AUDIT_REASON_ID);
    return stringValue;
  }

  get GetAuditReasonName(): string {
    const stringValue = this.getItem(AUDIT_REASON_NAME);
    return stringValue;
  }

  get GetAuditReasonOth(): string {
    const stringValue = this.getItem(AUDIT_REASON_OTH);
    return stringValue;
  }

  clear() {
    this.removeItem(TOKEN_KEY);
    this.removeItem(GEN_SET_KEY);
    this.removeItem(REFRESH_TOKEN_KEY);
    this.removeItem(CURRENT_USER_KEY);
    localStorage.clear();
  }

  setAuditReason(result: any) {
    this.setItem(AUDIT_REASON_ID, result.reasonId);
    this.setItem(AUDIT_REASON_NAME, result.reasonName);
    this.setItem(AUDIT_REASON_OTH, result.reasonOth);
  }

  removeAuditReason() {
    this.removeItem(AUDIT_REASON_ID);
    this.removeItem(AUDIT_REASON_NAME);
    this.removeItem(AUDIT_REASON_OTH);
  }
}
