import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard/e-commerce',
  //   pathMatch: 'full',
  // },
  // {
  //   path: '',
  //   redirectTo: 'auth/sign-in',
  //   pathMatch: 'full',
  // },
  // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES },
  // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  // { path: '**', redirectTo: 'auth/sign-in' }

  {
    path: '',
    redirectTo: 'auth/sign-in',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: ContentLayoutComponent,
    data: { title: 'Auth Views' },
    children: CONTENT_ROUTES, // Define authentication-related routes here
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'Full Views' },
    children: Full_ROUTES, // Define main app routes here
  },
  { path: '**', redirectTo: 'auth/sign-in' },
];

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'auth/sign-in',
//     pathMatch: 'full',
//   },
//   {
//     path: 'full',
//     component: FullLayoutComponent,
//     data: { title: 'Full Views' },
//     children: Full_ROUTES,
//   },
//   {
//     path: 'content',
//     component: ContentLayoutComponent,
//     data: { title: 'Content Views' },
//     children: CONTENT_ROUTES,
//   },
//   { path: '**', redirectTo: 'auth/sign-in' },
// ];

@NgModule({
  // , { useHash: false }
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  // imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
