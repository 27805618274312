
<!--start footer-->
<footer class="page-footer d-flex align-items-center flex-column flex-lg-row justify-content-between py-2">
    <div class="">
      <p class="mb-0">Copyright © 2024. All right reserved.</p>
    </div>
    <div class="">
      <a mat-button href="javascript:;">About</a>
      <a mat-button href="javascript:;">Contact</a>
      <a mat-button href="javascript:;">Support</a>
      <a mat-button href="javascript:;">Portfolio</a>
    </div>
  </footer>
  <!--end footer-->