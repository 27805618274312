import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { EnumService } from './enum.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DropDownService } from './drop-down.service';
import { UntypedFormGroup } from '@angular/forms';
import { Constant } from '../constants/constants';
import { ToasterService } from './toaster.service';
import { BaseSettingsModel } from '../models/base-settings.model';
//import { DataManager } from '@syncfusion/ej2-data';
import { Observable } from 'rxjs-compat/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { DateFormat } from 'src/app/shared/pipes/dateFormat.pipe';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  subscriptions: Subscription[] = [];
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  roleChanged = new Subject<number>();

  constructor(
    public data: DataService,
    public dropDown: DropDownService,
    public toast: ToasterService,
    public enums: EnumService,
    public storage: StorageService,
    private http: HttpClient,
    private locationStrategy: LocationStrategy,
    private dateformat: DateFormat
  ) {
   // this.loadBaseSettings();
  }

  confirmDelete(): boolean {
    if (!confirm(Constant.message.deleteConfirmation)) {
      return false;
    }
    return true;
  }

  validForm(fg: UntypedFormGroup, valid: boolean): boolean {
    Object.keys(fg.controls).forEach((controlName: string) => {
      const control = fg.get(controlName);
      if (control instanceof UntypedFormGroup) {
        valid = this.validForm(control, valid);
      } else {
        if (control.errors) {
          control.markAsTouched();
          valid = false;
        }
      }
    });

    return valid;
  }
  // formatDate(value: string): string | null {
  //   // Use DatePipe to format the date according to the settings from StorageService
  //   return this.datePipe.transform(value, 
  //     this.storage.GeneralSettings.dateFormat + ' ' + this.storage.GeneralSettings.timeFormat
  //   );
  // }
  public getTimeZone(): string {
    const datetimeString = new Date().toString(); 
    const timezone = this.getTimeZoneFromDate(datetimeString);
    return timezone;
  }

  getTimeZoneFromDate(dateString) {
    const regex = /\((?:\b\w+\s*)+\)$/;
    const match = regex.exec(dateString);
    if (match && match[1] == undefined) {
      return match[0];
    }
    return match ? match[1] : null;
  }

  public toBoolean(value: any) {
    if (!value) {
      return value;
    }

    if (typeof value === 'string') {
      value = value.trim().toLowerCase();
    }
    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }

  private loadBaseSettings(): void {
    this.http.get<BaseSettingsModel>('assets/base-settings.json').subscribe((settings) => {
      this.storage.setBaseSettings(settings);
    });
  }

  // public dataManager(url: string) {
  //   const baseUrl = this.storage.BaseUrl + url;
  //   const dm = new DataManager({
  //     url: baseUrl,
  //     headers: [
  //       {
  //         authorization: 'Bearer ' + this.storage.Token
  //       }
  //     ]
  //   });

  //   return dm;
  // }
  public dataManager(url: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.storage.Token}`);
    const baseUrl = this.storage.BaseUrl + url;
    return this.http.get(baseUrl, { headers });
  }



  validateSpace(e) {
    const firstChar = e.target.value;
  
    if (e.keyCode === 32) {
      const cursorPos = e.target.selectionStart;
  
      // If the space is at the beginning or after another space, prevent input
      if (cursorPos === 0 || (cursorPos === firstChar.length && firstChar[firstChar.length - 1] === ' ')) {
        return false;
      }
    }
    return true;
  }

  AlphaOnly(event: any): boolean {
    const keyCode = event.which || event.keyCode;
    const inputChar = String.fromCharCode(keyCode);
    const isAlphabetic = /[a-zA-Z ]/.test(inputChar);
  
    // If the input is alphabetic or a space, further validate spaces.
    return isAlphabetic ? this.validateSpace(event) : isAlphabetic;
  }  

  codenumberOnly(value: any) {
    let keyCode = value.which ? value.which : value.keyCode;
    let ret = (keyCode >= 48 && keyCode <= 57) || keyCode == 45;
    return ret;
  }

  removeSpecialChar(value: any) {
    let keyCode = value.which ? value.which : value.keyCode;
    let ret = ((keyCode > 64 && keyCode < 91) || (keyCode > 96 && keyCode < 123) || keyCode == 8 || keyCode == 32 || (keyCode >= 48 && keyCode <= 57));
    return ret;
  }
  
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  // beforeCommentAction(args: any): void {
  //   if (args.author !== this.storage.CurrentUser.userName) {
  //     if (args.type == 'Edit') {    // you can modify the type based on your requirements  
  //       args.cancel = true;
  //       this.toast.info('Only the author of the comment can edit it.');
  //     }
  //     else if (args.type == 'Delete') {
  //       args.cancel = true;
  //       this.toast.info('Only the author of the comment can delete it.');
  //     }
  //   }
  // }

  getDefaultData(): Observable<any> {
    return this.http.get<any>(`UserSetting/GetProjectDefaultData`);
  }

  getPageConfigutaion(screenCode: string): Observable<any> {
    return this.http.get<any>(`PageConfiguration/GetPageConfigurationByAppScreen/${screenCode}`)
  }
  createdFormatterOnlyDate = (field: string, data: Object, column: Object) => {
    return data[field] == null ? '' : this.dateformat.transform(new Date(data[field]).toLocaleString());
  }
}
