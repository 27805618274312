import { Component, OnInit } from '@angular/core';
import { SidebarService } from './../sidebar/sidebar.service'
import { HeadermenuService } from './../sidebar/headermenu.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { PermissionHandler } from 'src/app/auth/handlers/permission.handler';
import { Permissions } from 'src/app/auth/consts/permissions';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [UtilityService],
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  headerMenu: string[] = [];
  permits_submenu: boolean = false;
  violation_submenu: boolean = false;
  vehicles_submenu: boolean = false;
  payment_submenu: boolean = false;
  consfgurtation_submenu: boolean = false;
  apiconfiguration_submenu: boolean = false;
  violationanyltics_submenu: boolean = false;
  paymentsanyltics_submenu: boolean = false;
  locationmanagement_submenu: boolean = true
  permitsetting_submenu : boolean = false;
  settinguser_submenu: boolean = false
  settingvehicles_submenu: boolean = false
  settingpayment_submenu: boolean = false
  settingclassification_submenu : boolean =false
  settingdocument_submenu : boolean =false
  settingchecklist_submenu : boolean = false
  superadminbtn: boolean = false
  permissions = Permissions;
  isPermissionLocation=false;
  isPermissionPermitSetting = false;
  isPermissionUsers=false;
  isPermissionVehicles=false;
  isPermissionPayment=false;
  isPermissionClassification=false;
  isPermissionDocumentCategory=false;
  settingpricingpackage_submenu : boolean = false

  languageList: any = [
    { code: 'en', name: "English", img: 'assets/images/county/01.png' },
    { code: 'fr', name: "French", img: 'assets/images/county/03.png' },
    { code: 'es', name: "Spanish", img: 'assets/images/county/06.png' },
    { code: 'he', name: "Hindi", img: 'assets/images/county/08.png' }
  ]
  languageflg: any = 'assets/images/county/01.png';
  constructor(private utils: UtilityService
    , private cdr: ChangeDetectorRef
    , public sidebarservice: SidebarService
    , public HeadermenuService: HeadermenuService
    , private router: Router
    , private translateService: TranslateService
    , public permissionHandler: PermissionHandler) {
  }

  ngOnInit() {
    this.HeadermenuService.headerMenu$.subscribe(menuItems => {
      this.permits_submenu = false;
      this.violation_submenu = false;
      this.vehicles_submenu = false;
      this.payment_submenu = false;
      this.consfgurtation_submenu = false;
      this.apiconfiguration_submenu = true;
      this.violationanyltics_submenu = false;
      this.paymentsanyltics_submenu = false;
      this.locationmanagement_submenu = true
      this.permitsetting_submenu = false;
      this.settinguser_submenu = false
      this.settingvehicles_submenu = false
      this.settingpayment_submenu = false
      this.settingclassification_submenu = false
      this.settingdocument_submenu =false;
      this.settingchecklist_submenu =false;
      this.headerMenu = menuItems;
      this.superadminbtn = false
      this.hidenavigation = false
      this.settingpricingpackage_submenu = false
});
    this.checkPermissions();
  }
  hidenavigation: boolean = true
  showsuperadmin() {
    this.superadminbtn = true
    this.router.navigate(['super-admin/rokerplus']);
    if (this.superadminbtn) {
      this.hidenavigation = true;
    }
  }

  logoutbtn() {
    this.utils.storage.clear();
    this.router.navigate(['/auth/sign-in'])
  }
  paymentpage() {
    this.router.navigate(['/components/public-portal/payment'])
    this.hidenavigation = true
    this.superadminbtn = false
  }
  permits_submenubtn() {
    this.permits_submenu = true
  }
  dashboardclick() {
    this.permits_submenu = false

  }
  vioaltiondashboardbtn() {
    this.violation_submenu = false;
    this.vehicles_submenu = false
    this.payment_submenu = false;
    this.payment_submenu = false;
    this.consfgurtation_submenu = false;

  }
  vioaltionserachbtn() {
    this.violation_submenu = false;
    this.vehicles_submenu = false;
    this.payment_submenu = false;
    this.payment_submenu = false;
    this.consfgurtation_submenu = false;
  }
  violationbtnsub_menu() {
    this.violation_submenu = true;
    this.vehicles_submenu = false;
    this.payment_submenu = false;
    this.payment_submenu = false;
    this.consfgurtation_submenu = false;
  }
  vehiclebtnsub_menu() {
    this.vehicles_submenu = true;
    this.violation_submenu = false;
    this.payment_submenu = false;
    this.payment_submenu = false;
    this.consfgurtation_submenu = false;
  }
  paymentbtnsub_menu() {
    this.payment_submenu = true;
    this.vehicles_submenu = false;
    this.violation_submenu = false;
    this.consfgurtation_submenu = false;

  }
  configurationbtnsub_menu() {
    this.consfgurtation_submenu = true;
    this.payment_submenu = false;
    this.vehicles_submenu = false;
    this.violation_submenu = false;
    this.payment_submenu = false;
    this.payment_submenu = false;

  }
  analytics_permitbtn() {
    this.apiconfiguration_submenu = true;
    this.violationanyltics_submenu = false;
    this.paymentsanyltics_submenu = false;
  }
  analytics_violationbtn() {
    this.apiconfiguration_submenu = false;
    this.violationanyltics_submenu = true;
    this.paymentsanyltics_submenu = false

  }
  analytics_paymentbtn() {
    this.apiconfiguration_submenu = false;
    this.violationanyltics_submenu = false;
    this.paymentsanyltics_submenu = true
  }
  location_mangbtn() {    
    this.locationmanagement_submenu = true;
    this.permitsetting_submenu = false;
    this.settinguser_submenu = false
    this.settingvehicles_submenu = false
    this.settingpayment_submenu = false
  this.settingclassification_submenu =false
  this.settingdocument_submenu = false;
  this.settingchecklist_submenu = false;
  this.settingpricingpackage_submenu = false
}
  permitsetting_mangbtn()
  {
    this.locationmanagement_submenu = false;
    this.permitsetting_submenu = true;
    this.settinguser_submenu = false
    this.settingvehicles_submenu = false
    this.settingpayment_submenu = false
  this.settingclassification_submenu =false
  this.settingdocument_submenu = false;
  this.settingchecklist_submenu = false;
  this.settingpricingpackage_submenu = false
}
  user_btn() {
    this.locationmanagement_submenu = false;
    this.permitsetting_submenu = false;
    this.settinguser_submenu = true
    this.settingvehicles_submenu = false
    this.settingpayment_submenu = false
    this.settingclassification_submenu =false
    this.settingdocument_submenu = false;
    this.settingchecklist_submenu =false;
    this.settingpricingpackage_submenu = false
  }
  settingvehicle_btn() {
    this.locationmanagement_submenu = false;
    this.permitsetting_submenu = false;
    this.settinguser_submenu = false
    this.settingvehicles_submenu = true
    this.settingpayment_submenu = false
    this.settingclassification_submenu =false
    this.settingdocument_submenu = false;
    this.settingchecklist_submenu =false;
    this.settingpricingpackage_submenu = false
  }
  settingpayment_btn() {
    this.locationmanagement_submenu = false;
    this.permitsetting_submenu = false;
    this.settinguser_submenu = false
    this.settingvehicles_submenu = false
    this.settingpayment_submenu = true
    this.settingclassification_submenu =false
    this.settingdocument_submenu = false;
    this.settingchecklist_submenu =false;
    this.settingpricingpackage_submenu = false
  }
 
  theme_name = 'dark_mode'

  toggleSearch: boolean = false;

  darkMode() {

    if (this.theme_name == 'light_mode') {
      document.querySelector("html").classList.replace('dark_mode', 'light_mode');
      this.theme_name = 'dark_mode'

    } else if (this.theme_name == 'dark_mode') {
      document.querySelector("html").classList.replace('light_mode', 'dark_mode');
      this.theme_name = 'light_mode'

    }
    return this.theme_name;
  }

  getSideBarSate() {
    return this.sidebarservice.getSidebarState();
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  openSearch() {
    this.toggleSearch = true;
  }

  searchClose() {
    this.toggleSearch = false;
  }
  selectLanguage(lan: any) {
    this.languageflg = lan.img;
    this.translateService.use(lan.code).subscribe(() => {
      this.cdr.detectChanges();
    });
  }
  checkPermissions(): any {  
    let zonePermissions = this.permissionHandler.checkPermission([this.permissions.zone.View]);
    let groupPermissions = this.permissionHandler.checkPermission([this.permissions.group.View]);
    let locationPermissions = this.permissionHandler.checkPermission([this.permissions.location.View]);

    let userPermissions = this.permissionHandler.checkPermission([this.permissions.roles.View])
    let rolesPermissions = this.permissionHandler.checkPermission([this.permissions.users.View]);

    let vehicleColorsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_colors.View]);
    let vehicleMakesPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_makes.View]);
    let vehicleModelsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_models.View]);
    let vehicleLicencePlatePermissions = this.permissionHandler.checkPermission([this.permissions.license_plate.View]);

    let paymentGatewaysPermissions = this.permissionHandler.checkPermission([this.permissions.Payment_gateways.View]);

    let classificationPermissions = this.permissionHandler.checkPermission([this.permissions.classification.View]);
    let documentCategoryPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);
    let checklistsPermissions = this.permissionHandler.checkPermission([this.permissions.checklist.View]);
    let pricingpackagePermissions = this.permissionHandler.checkPermission([this.permissions.pricing_package.View]);
 
    this.isPermissionLocation=groupPermissions || zonePermissions || locationPermissions;
    if(!this.isPermissionLocation){
      this.locationmanagement_submenu = false;
    }    

    this.isPermissionUsers = userPermissions || rolesPermissions;
    if(!this.isPermissionUsers){
      this.settinguser_submenu = false;
    }

    this.isPermissionVehicles=vehicleColorsPermissions ||vehicleMakesPermissions|| vehicleModelsPermissions||vehicleLicencePlatePermissions;
    if(!this.isPermissionVehicles){
      this.settingvehicles_submenu = false;
    }

    this.isPermissionPayment=paymentGatewaysPermissions;
    if(!this.isPermissionPayment){
      this.settingpayment_submenu =false
    }

    this.isPermissionPermitSetting=classificationPermissions || documentCategoryPermissions || checklistsPermissions || pricingpackagePermissions;
    if(!this.isPermissionPermitSetting){ 
      this.permitsetting_submenu = false;
    }  
   
  }

  // checkPermissions(): any {  
  //   let zonePermissions = this.permissionHandler.checkPermission([this.permissions.zone.View]);
  //   let groupPermissions = this.permissionHandler.checkPermission([this.permissions.group.View]);
  //   let locationPermissions = this.permissionHandler.checkPermission([this.permissions.location.View]);

  //   let userPermissions = this.permissionHandler.checkPermission([this.permissions.roles.View])
  //   let rolesPermissions = this.permissionHandler.checkPermission([this.permissions.users.View]);

  //   let vehicleColorsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_colors.View]);
  //   let vehicleMakesPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_makes.View]);
  //   let vehicleModelsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_models.View]);
  //   let vehicleLicencePlatePermissions = this.permissionHandler.checkPermission([this.permissions.license_plate.View]);

  //   let paymentGatewaysPermissions = this.permissionHandler.checkPermission([this.permissions.Payment_gateways.View]);

  //   let classificationPermissions = this.permissionHandler.checkPermission([this.permissions.classification.View]);

  //   let documentCategoryPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);

  //   this.isPermissionLocation=groupPermissions || zonePermissions || locationPermissions;
  //   if(!this.isPermissionLocation){
  //     this.settinguser_submenu = true
  //   }    

  //   this.isPermissionUsers = userPermissions || rolesPermissions;
  //   if(!this.isPermissionUsers){
  //     this.settingvehicles_submenu = true
  //   }

  //   this.isPermissionVehicles=vehicleColorsPermissions ||vehicleMakesPermissions|| vehicleModelsPermissions||vehicleLicencePlatePermissions;
  //   if(!this.isPermissionVehicles){
  //     this.settingpayment_submenu = true
  //   }

  //   this.isPermissionPayment=paymentGatewaysPermissions;
  //   if(!this.isPermissionPayment){
  //     this.settingclassification_submenu = true
  //   }

  //   this.isPermissionClassification=classificationPermissions;
  //   if(!this.isPermissionClassification){
  //     this.settingdocument_submenu = true
  //   }

  //   this.isPermissionDocumentCategory=documentCategoryPermissions;
  //   if(!this.isPermissionDocumentCategory){
    
  //   }  
    
  // }
}
