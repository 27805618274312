import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();
  private activeRequests = 0;

  setLoading(isLoading: boolean) {
    if (isLoading) {
      this.activeRequests++;
      if (this.activeRequests === 1) {
        setTimeout(() => {
          if (this.activeRequests > 0) {
            this.loading.next(true);
          }
        }, 300); // 🔥 Adds a 300ms delay to avoid quick flickers
      }
    } else {
      this.activeRequests--;
      if (this.activeRequests === 0) {
        setTimeout(() => {
          if (this.activeRequests === 0) {
            this.loading.next(false);
          }
        }, 300); // 🔥 Delay hiding the loader slightly
      }
    }
  }

  // private loading: boolean = false;

  // constructor() { }

  // setLoading(loading: boolean) {
  //   this.loading = loading;
  // }

  // getLoading(): boolean {
  //   return this.loading;
  // }
}