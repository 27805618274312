import { APP_INITIALIZER, importProvidersFrom, NgModule, provideZoneChangeDetection } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, provideHttpClient,withInterceptorsFromDi, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { MatModule } from './appModules/mat.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { provideMomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { StorageService } from './core/services/storage.service';
import { DateTimeFormat } from './shared/pipes/dateFormatTime.pipe';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { LoadingInterceptor } from './shared/loader/loading.interceptor';
import { CoreModule } from './core/core.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatTabsModule } from '@angular/material/tabs'; 
import { QuillModule } from 'ngx-quill';
import { AddVehiclePermitComponent } from './components/bookpermit/add-vehicle-permit/add-vehicle-permit.component';
import { NgxStripeModule, StripeService } from 'ngx-stripe';
@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    DateTimeFormat,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule, // Include FormsModule
    ReactiveFormsModule, // If you are using Reactive Forms
    MatModule,
    CoreModule,
    MatTabsModule,
    QuillModule.forRoot(),
    CollapseModule.forRoot() ,
    NgxStripeModule.forRoot(''),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  ToastrModule.forRoot({    // Configure the Toastr module
    timeOut: 3000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    closeButton: true,
  }),
  ],
  // pk_test_51QoK3i2NUYtMDybrrHNeW3p72Nu0ldLXBm0rq7CLHaS5Zczs1jlLXkUlgXww5t8gBulSOd7WrU8i9D9rkwfAaN2F00xHBg9TmI
  providers: [
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    StorageService,
    provideMomentDatetimeAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'YYYY-MM-DD HH:mm',
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'YYYY-MM-DD HH:mm',
        monthYearLabel: 'YYYY MMMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        popupHeaderDateLabel: 'MMM DD, ddd',
      },
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


