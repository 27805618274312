import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SpinnerComponent {
  loading$ = this.loader.loading$; // 🔥 Subscribe to loader state
  constructor(public loader: LoaderService) {}
}
