import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatModule } from 'src/app/appModules/mat.module';

@Component({
  selector: 'app-expire-session-dialog',
  standalone: true, // Declare it as a standalone component
  imports: [MatModule, CommonModule, MatModule], // Import the necessary modules
  templateUrl: './expire-session-dialog.component.html',
  styleUrls: ['./expire-session-dialog.component.scss']
})
export class ExpireSessionDialogComponent {
  countdown: number = 60;
  private timer: any;
  constructor(
    public dialogRef: MatDialogRef<ExpireSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.autoLogout();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  autoLogout(): void {
    clearInterval(this.timer);
    this.dialogRef.close(false);
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
}
