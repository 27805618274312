<header
  class="top-header mat-elevation-z5 d-block"
  [class.hidden]="toggleSearch"
  [ngClass]="{ test: getSideBarSate() }"
>
  <div class="d-flex justify-content-between" style="padding: 0 1.5rem">
    <div class="d-flex" style="height: 65px">
      <div class="gap-1 d-flex align-items-center">
        <button mat-icon-button (click)="toggleSidebar()">
          <mat-icon class="material-icons-outlined">menu</mat-icon>
        </button>
      </div>
      <div class="d-flex align-items-center" *ngIf="superadminbtn">
        <button mat-button>
          <a mat-list-item routerLink="super-admin/rokerplus"
            >RokerPLUS Tenants
          </a>
        </button>
        <button mat-button>
          <a mat-list-item routerLink="super-admin/email-template"
            >Manage Email Template
          </a>
        </button>
        <button mat-button>
          <a mat-list-item routerLink="super-admin/reports">Reports </a>
        </button>
      </div>

      <div class="d-flex" *ngIf="!hidenavigation">
        <div class="new_menu flex-grow-1">
          <mat-card class="flex-grow-1 gap-1">
            <!-- permits---navigation-icon -->
            <div
              *ngIf="
                headerMenu.length === 2 &&
                (headerMenu.includes('Permits') ||
                  headerMenu.includes('Permis') ||
                  headerMenu.includes('Permisos'))
              "
            >
              <mat-card-content>
                <div class="slider_headerresponsive">
                  <button mat-button (click)="dashboardclick()">
                    <a mat-list-item routerLink="components/permit/dashboard">{{
                      headerMenu[0]
                    }}</a>
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="belowMenu"
                    (click)="permits_submenubtn()"
                  >
                    <a mat-list-item routerLink="components/permit/insight">
                      {{ headerMenu[1] }}</a
                    >
                  </button>
                </div>
              </mat-card-content>
            </div>
            <!-- permits---navigation-icon -->

            <!-- violation--tabs--start -->

            <div
              *ngIf="
                headerMenu.length === 6 &&
                (headerMenu.includes('Violation') ||
                  headerMenu.includes('Violación'))
              "
            >
              <mat-card-content>
                <div class="slider_headerresponsive">
                  <button
                    mat-button
                    [matMenuTriggerFor]="aboveMenu"
                    (click)="vioaltiondashboardbtn()"
                  >
                    <a
                      mat-list-item
                      routerLink="components/violation/dashboard"
                    >
                      {{ headerMenu[0] }}</a
                    >
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="aboveMenu"
                    (click)="vioaltionserachbtn()"
                  >
                    <a mat-list-item routerLink="components/violation/search">
                      {{ headerMenu[1] }}</a
                    >
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="violationmanage"
                    (click)="violationbtnsub_menu()"
                  >
                    <a mat-list-item routerLink="components/violation/manage">
                      {{ headerMenu[2] }}</a
                    >
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="vehicleactivity"
                    (click)="vehiclebtnsub_menu()"
                  >
                    <a
                      mat-list-item
                      routerLink="components/vehicleactivity/insight"
                    >
                      {{ headerMenu[3] }}</a
                    >
                  </button>

                  <button
                    mat-button
                    [matMenuTriggerFor]="payment"
                    (click)="paymentbtnsub_menu()"
                  >
                    <a mat-list-item routerLink="components/payment/insight">
                      {{ headerMenu[4] }}</a
                    >
                  </button>

                  <button
                    mat-button
                    [matMenuTriggerFor]="Configuration"
                    (click)="configurationbtnsub_menu()"
                  >
                    <a
                      mat-list-item
                      routerLink="components/configuration/location"
                    >
                      {{ headerMenu[5] }}</a
                    >
                  </button>
                </div>
              </mat-card-content>
            </div>
            <!-- violation--tabs--start -->

            <!-- notice--tabs--start -->
            <div
              *ngIf="
                headerMenu.length &&
                (headerMenu.includes('Collection Engine') ||
                  headerMenu.includes('Motor de colección') ||
                  headerMenu.includes('Moteur de collecte'))
              "
            >
              <mat-card-content>
                <div class="slider_headerresponsive">
                  <button mat-button [matMenuTriggerFor]="aboveMenu">
                    <a mat-list-item routerLink="components/notice/dashboard">
                      {{ headerMenu[0] }}</a
                    >
                  </button>
                  <button mat-button [matMenuTriggerFor]="aboveMenu">
                    <a mat-list-item routerLink="components/notice/insight">
                      {{ headerMenu[1] }}</a
                    >
                  </button>
                  <button mat-button [matMenuTriggerFor]="violationmanage">
                    <a
                      mat-list-item
                      routerLink="components/notice/collection-engine"
                    >
                      {{ headerMenu[2] }}</a
                    >
                  </button>
                </div>
              </mat-card-content>
            </div>
            <!-- notice--tabs--end -->

            <!-- analytics--navigation -->
            <div
              *ngIf="
                headerMenu.length === 3 &&
                (headerMenu.includes('Permit') ||
                  headerMenu.includes('Permiso') ||
                  headerMenu.includes('Permis'))
              "
            >
              <mat-card-content>
                <div class="slider_headerresponsive">
                  <button
                    mat-button
                    [matMenuTriggerFor]="Permit_analytics"
                    (click)="analytics_permitbtn()"
                  >
                    <a mat-list-item routerLink="components/analytics/permits">
                      {{ headerMenu[0] }}</a
                    >
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="violationmanagemet"
                    (click)="analytics_violationbtn()"
                  >
                    <a mat-list-item routerLink="components/analytics/report">
                      {{ headerMenu[1] }}</a
                    >
                  </button>

                  <button
                    mat-button
                    [matMenuTriggerFor]="payment_anayltics"
                    (click)="analytics_paymentbtn()"
                  >
                    <a
                      mat-list-item
                      routerLink="components/analytics/user-payment-tracking"
                    >
                      {{ headerMenu[2] }}</a
                    >
                  </button>
                </div>
              </mat-card-content>
            </div>
            <!-- analytics--navigation -->

            <!-- setting--navigation--start -->
            <div
              *ngIf="
                headerMenu.length &&
                (headerMenu.includes('Vehicles') ||
                  headerMenu.includes('Vehículos') ||
                  headerMenu.includes('Véhicules'))
              "
            >
              <mat-card-content>
                <div class="slider_headerresponsive">
                  <button *ngIf="isPermissionLocation"
                    mat-button
                    [matMenuTriggerFor]="Permit_analytics"
                    (click)="location_mangbtn()"
                  >
                    <a mat-list-item routerLink="components/setting/zone">
                      {{ headerMenu[0] }}</a>
                  </button>
                  <button *ngIf="isPermissionUsers"
                    mat-button
                    [matMenuTriggerFor]="userRoles"
                    (click)="user_btn()"
                  >
                    <a mat-list-item routerLink="components/setting/roles">
                      {{ headerMenu[1] }}</a
                    >
                  </button>
                  <button *ngIf="isPermissionVehicles"
                    mat-button
                    [matMenuTriggerFor]="vehicles_color"
                    (click)="settingvehicle_btn()" 
                  >
                    <a mat-list-item routerLink="components/setting/vehicle-colors">
                      {{ headerMenu[2] }}</a
                    >
                  </button>
                  <button
                    mat-button
                    [matMenuTriggerFor]="paymentgateway"
                    (click)="settingpayment_btn()"
                  >
                    <a mat-list-item routerLink="components/payment">
                      {{ headerMenu[3] }}</a
                    >
                  </button>
                  <button 
                    mat-button *ngIf="isPermissionPermitSetting"
                    [matMenuTriggerFor]="Permit_setting"
                    (click)="permitsetting_mangbtn()"
                  >
                    <a mat-list-item routerLink="components/setting/classification">
                      {{ headerMenu[4] }}</a>
                  </button>

                </div>
              </mat-card-content>
            </div>

            <!-- setting--navigation--end -->
          </mat-card>
        </div>
      </div>
    </div>

    <!-- seting---submenu--start--here -->
    <div class="d-flex align-items-center gap-1">
      <button mat-icon-button (click)="openSearch()">
        <mat-icon class="material-icons-outlined">search</mat-icon>
      </button>
      <button
        mat-icon-button
        [matMenuTriggerFor]="language"
        class="d-none d-sm-flex"
      >
        <img src="{{ this.languageflg }}" alt="" />
      </button>
      <mat-menu #language class="rounded-4">
        <ng-container *ngFor="let lan of languageList">
          <button mat-menu-item (click)="selectLanguage(lan)">
            <div class="d-flex align-items-center gap-2">
              <img src="{{ lan.img }}" width="24" alt="" />
              <span>{{ lan.name }}</span>
            </div>
          </button>
        </ng-container>

      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="Notification">
        <mat-icon
          class="material-icons-outlined"
          matBadge="7"
          matBadgePosition="after"
          matBadgeColor="warn"
          >notifications</mat-icon
        >
      </button>
      <button mat-icon-button (click)="paymentpage()">
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          height="512"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon">
            <path
              d="m21.722 21.57c.238.339.155.807-.184 1.044-.339.238-.807.155-1.044-.184l-1.619-2.312c-.238-.339-.155-.807.184-1.045.339-.237.807-.155 1.044.185z"
            />
            <path
              d="m22.137 17.291c.421.243.659.711.606 1.194-.053.482-.386.888-.849 1.034l-2.067.652-1.319 1.719c-.295.385-.791.559-1.262.444-.472-.116-.831-.499-.915-.977l-.998-5.67c-.083-.469.114-.943.503-1.216.39-.272.903-.295 1.315-.057z"
            />
            <path
              d="m1.25 6.75v-2.25c0-1.795 1.455-3.25 3.25-3.25h15c.862 0 1.689.342 2.298.952.61.609.952 1.436.952 2.298v2.25zm21.5 1.5v8.244c-.037-.024-.074-.047-.113-.069l-4.987-2.877c-.748-.432-1.679-.391-2.387.104-.708.496-1.065 1.357-.915 2.208l.998 5.67c.084.476.317.899.651 1.22h-11.497c-1.795 0-3.25-1.455-3.25-3.25v-11.25zm-17.5-4.75c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zm2.5 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zm2.5 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75z"
            />
          </g>
        </svg>
      </button>
      <mat-menu #Notification class="rounded-4 dropdown-notification">
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-red"
            >shopping_cart</mat-icon
          >
          <span>New Sale from Gumroad</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-purple">email</mat-icon>
          <span>Check your new message</span>
        </button>

        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-green">task</mat-icon>
          <span>You have 5 new task</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-blue"
            >supervisor_account</mat-icon
          >
          <span>10 New users registered</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-amber"
            >card_giftcard</mat-icon
          >
          <span>You have received 1 new gift</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-teal"
            >done_all</mat-icon
          >
          <span>Payment succesfully completed</span>
        </button>
        <div class="mx-3 my-2">
          <button mat-raised-button color="primary" class="rounded-4 w-100">
            View All
          </button>
        </div>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="UserProfile">
        <mat-icon class="material-icons-outlined">account_circle</mat-icon>
      </button>
      <mat-menu #UserProfile class="rounded-4 dropdown-userprofile">
        <button
          mat-menu-item
          class="d-flex align-items-center justify-content-center text-center py-2"
        >
          <div>
            <img
              src="assets/images/avatars/avatar-1.png"
              width="80"
              height="80"
              class="rounded-circle"
              alt=""
            />
          </div>
          <div>
            <h4 class="username mb-0">Hi, codervent!</h4>
            <p class="mb-0">coderventxyz.com</p>
          </div>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">account_circle</mat-icon>
          <span>Profile</span>
          <!-- mat-list-item
          routerLink="" -->
        </button>
        <button mat-menu-item (click)="showsuperadmin()">
          <mat-icon class="material-icons-outlined text-purple">email</mat-icon>

          <a mat-list-item routerLink="super-admin/rokerplus" class="p-0">
            <span>Super admin</span>
          </a>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">settings</mat-icon>
          <span>Setting</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">dashboard</mat-icon>
          <span>Dashboard</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">account_balance</mat-icon>
          <span>Balance</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">file_download</mat-icon>
          <span>Downloads</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logoutbtn()">
          <mat-icon class="material-icons-outlined">logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div>
    <!-- permits---submenu--start -->
    <div
      *ngIf="
        headerMenu.length === 2 &&
        (headerMenu.includes('Permits') ||
          headerMenu.includes('Permiso') ||
          headerMenu.includes('Permis'))
      "
    >
      <div class="submenu_block" *ngIf="permits_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/permit/insight">{{
                "Sidebar.LblInsights" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/permit/manual-validation"
                >{{ "Sidebar.LblManualValidation" | translate }}</a
              >
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/permit/import-validation"
                >{{ "Sidebar.LblImportValidations" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- permits---submenu--start -->

    <!-- violation--submenu--start -->
    <div
      *ngIf="
        headerMenu.length === 6 &&
        (headerMenu.includes('Violation') || headerMenu.includes('Violación'))
      "
    >
      <!-- violation-submenu--start -->
      <div class="submenu_block" *ngIf="violation_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/violation/manage">{{
                "Sidebar.LblManage" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/violation/add-violation"
                >{{ "Sidebar.LblAdd" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- violation-submenu--end -->

      <!--*************vehicles_submenu*************** -->
      <!-- violation-submenu--start -->
      <div class="submenu_block" *ngIf="vehicles_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a
                mat-list-item
                routerLink="components/vehicleactivity/insight"
                >{{ "Sidebar.LblInsights" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- violation-submenu--end --> `

      <!--*************vehicles_submenu*************** -->

      <!--*************payment_submenu*************** -->
      <!-- payement-submenu--start -->
      <div class="submenu_block" *ngIf="payment_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/payment/insight">{{
                "Sidebar.LblInsights" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/payment/pay-violation">{{
                "Sidebar.LblPayViolations" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/payment/import-payments"
                >{{ "Sidebar.LblImportPayments" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- payement-submenu--end -->

      <!--*************payment_submenu*************** -->

      <!--*************consfgurtation_submenu*************** -->
      <!-- consfgurtation-submenu--start -->
      <div class="submenu_block" *ngIf="consfgurtation_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/configuration/location">{{
                "Sidebar.LblLocations" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/configuration/violation"
                >{{ "Header.LblViolations" | translate }}</a
              >
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/configuration/connection"
                >{{ "Sidebar.LblConnections" | translate }}</a
              >
            </li>
            <li>
              <a mat-list-item routerLink="components/configuration/payment">{{
                "Sidebar.LblPayments" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/configuration/notification"
                >{{ "Sidebar.LblNotification" | translate }}</a
              >
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/configuration/appeal-configuration"
                >{{ "Sidebar.LblAppeals" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- consfgurtation-submenu--end -->

      <!--***********consfgurtation_submenu***************** -->
    </div>
    <!-- violation--submenu--start -->

    <!-- anayltics---submenu--start--**************** -->

    <!-- anayltics---submenu--- -->
    <div
      *ngIf="
        headerMenu.length &&
        (headerMenu.includes('Permit') ||
          headerMenu.includes('Permiso') ||
          headerMenu.includes('Permis'))
      "
    >
      <!-- api--configuration--submenu--start -->
      <div class="submenu_block" *ngIf="apiconfiguration_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/analytics/permits">{{
                "Common.LblAPIConfigurationReport" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- api--configuration--submenu--start -->

      <!-- analytics-violation--submenu--start -->
      <div class="submenu_block" *ngIf="violationanyltics_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/analytics/report">{{
                "Common.LblViolationsReports" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/analytics/due-summary">{{
                "Common.LblViolationDueSummary" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/analytics/patroller">{{
                "Common.LblViolationbyPatroller" | translate
              }}</a>
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/analytics/audit-history"
                >{{ "Common.LblViolationAuditHistory" | translate }}</a
              >
            </li>
            <li>
              <a mat-list-item routerLink="components/analytics/collection">{{
                "Common.LblCollections" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- analytics-violation--submenu--end -->

      <!-- analytics-violation--submenu--start -->
      <div class="submenu_block" *ngIf="paymentsanyltics_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a
                mat-list-item
                routerLink="components/analytics/user-payment-tracking"
                >{{ "Common.LblUserPaymentTracking" | translate }}</a
              >
            </li>
            <li>
              <a
                mat-list-item
                routerLink="components/analytics/payments-reconciliation"
                >{{ "Common.LblPaymentsReconciliation" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- analytics-violation--submenu--end -->
    </div>

    <!-- anayltics---submenu--- -->

    <!-- anayltics---submenu--start--**************** -->

    <!-- seting---submenu--start--here -->

    <div
      *ngIf="
        headerMenu.length &&
        (headerMenu.includes('Vehicles') ||
          headerMenu.includes('Vehículos') ||
          headerMenu.includes('Véhicules'))
      "
    >
      <!-- location--management--submenu--start -->
      <div class="submenu_block" *ngIf="locationmanagement_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/setting/zone" *ngIf="permissionHandler.checkPermission([permissions.zone.View])">{{
                "Sidebar.LblZones" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/setting/group" *ngIf="permissionHandler.checkPermission([permissions.group.View])">{{
                "Sidebar.LblGroups" | translate
              }}</a>
            </li>       
            <li>
              <a mat-list-item routerLink="components/setting/location" *ngIf="permissionHandler.checkPermission([permissions.location.View])">{{
                "Sidebar.LblLocations" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- location--management--submenu--start -->
      <!-- user--management--submenu--start -->
      <div class="submenu_block" *ngIf="settinguser_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/setting/roles" *ngIf="permissionHandler.checkPermission([permissions.roles.View])">{{
                "Sidebar.LblRoles" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/setting/users" *ngIf="permissionHandler.checkPermission([permissions.users.View])">{{
                "Sidebar.LblUsers" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- user--management--submenu--start -->
      <!-- vehicles----submenu--start -->
      <div class="submenu_block" *ngIf="settingvehicles_submenu">
        <div class="submenu_list">
          <ul>
            <li *ngIf="settingvehicles_submenu">
              <a mat-list-item routerLink="components/setting/vehicle-colors" *ngIf="permissionHandler.checkPermission([permissions.vehicle_colors.View])">{{
                "Sidebar.LblColors" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/setting/vehicle-makes" *ngIf="permissionHandler.checkPermission([permissions.vehicle_makes.View])">{{
                "Sidebar.LblMakes" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/setting/vehicle-models" *ngIf="permissionHandler.checkPermission([permissions.vehicle_models.View])">{{
                "Sidebar.LblModels" | translate
              }}</a>
            </li>
            <li>
              <a mat-list-item routerLink="components/setting/license-plate" *ngIf="permissionHandler.checkPermission([permissions.license_plate.View])">{{
                "Sidebar.LblLicencePlates" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- vehicles--management--submenu--start -->

      <!-- setting--payements----submenu--start -->
      <div class="submenu_block" *ngIf="settingpayment_submenu">
        <div class="submenu_list">
          <ul>
            <li>
              <a mat-list-item routerLink="components/setting/payment">{{
                "Sidebar.LblPayments" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- setting--payements--submenu--end -->
      
        <!-- Setting permit setting submenu start  -->
        <div class="submenu_block" *ngIf="permitsetting_submenu">
          <div class="submenu_list">
            <ul>
              <li>
                <a mat-list-item routerLink="components/setting/classification" *ngIf="permissionHandler.checkPermission([permissions.classification.View])">{{
                  "Sidebar.LblClassification" | translate
                }}</a>
              </li>
              <li>
                <a mat-list-item routerLink="components/setting/document-category" *ngIf="permissionHandler.checkPermission([permissions.document_category.View])">{{
                  "Sidebar.LblDocumentCategory" | translate
                }}</a>
              </li>       
              <li>
                <a mat-list-item routerLink="components/setting/checklist" *ngIf="permissionHandler.checkPermission([permissions.checklist.View])">{{
                  "Sidebar.LblChecklist" | translate
                }}</a>
              </li>
              <li>
                <a mat-list-item routerLink="components/setting/pricing-package" *ngIf="permissionHandler.checkPermission([permissions.pricing_package.View])">{{
                  "Sidebar.LblPricingPackage" | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- setting permit setting submenu end -->
    </div>
  </div>
</header>

<!--start search toolbar-->
<mat-toolbar
  class="searchbar mat-elevation-z5 justify-content-between"
  [ngClass]="{ 'full-search': getSideBarSate() }"
  [class.searchVisible]="toggleSearch"
>
  <button mat-icon-button class="btn-search">
    <mat-icon class="material-icons-outlined">search</mat-icon>
  </button>
  <input class="w-100 search-control" placeholder="Search" />
  <button mat-icon-button class="btn-search" (click)="searchClose()">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</mat-toolbar>
<!--end search toolbar-->
