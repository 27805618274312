import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StorageService } from 'src/app/core/services/storage.service';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class DateFormat implements PipeTransform {
  dateformate: string;
  constructor(private storageService: StorageService) {}

  transform(value: string, format: string = 'yyyy-MM-dd HH:mm:ss'): string | null {
    if (!value) return null;

    const datePipe = new DatePipe('en-IN');
    return datePipe.transform(value, format, 'UTC'); // Use 'UTC' to prevent timezone conversion
  }

  // transform(value: string,format: string = '') {
  //   const datePipe = new DatePipe('en-IN');
  //   value = datePipe?.transform(value,  format || this.storageService.GeneralSettings.dateFormat);
  //   return value;
  // }

}
