<app-header></app-header>
<app-sidebar></app-sidebar>
<main class="main-content" [ngClass]="{ 'test-2': getSideBarState() }">
  <div style="margin: 40px 0px">
    <router-outlet></router-outlet>
    <div
      class="overlay"
      (click)="toggleSidebar()"
      [ngClass]="{ show: getSideBarState() }"
    ></div>
  </div>
</main>
