<!-- <h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>{{ data.description }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onClose(false)">CLOSE</button>
  <button mat-button color="primary" (click)="onClose(true)">{{ data.btnText }}</button>
</mat-dialog-actions> -->
<mat-dialog-content class="dialog-container">
    <mat-icon class="dialog-icon">info</mat-icon>
    <h2 class="dialog-title">{{ data.title }}</h2>
    <p class="dialog-description">{{ data.description }}</p>
    <p class="countdown-timer">Session expires in: <strong>{{ countdown }}s</strong></p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-stroked-button [mat-dialog-close]="true" (click)="onClose(false)" class="cancel-btn">Loggout</button>

    <button mat-button color="primary" class="confirm-btn" (click)="onClose(true)">
        {{ data.btnText }}
    </button>
</mat-dialog-actions>