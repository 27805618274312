import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true); // 🔥 Show loader
  
    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.setLoading(false); // 🔥 Hide loader
      })
    );
  }
  
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   return next.handle(req).pipe(
    //     catchError((error: HttpErrorResponse) => {
    //       if (error.status === 401) {
    //         setTimeout(() => {
    //           this.router.navigateByUrl('/auth/sign-in'); // ✅ Asynchronous navigation
    //         }, 0);
    //       }
    //       return throwError(error);
    //     })
    //   );
    // }
 

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   this.totalRequests++;
  //   this.loadingService.setLoading(true);
  //   return next.handle(request).pipe(
  //     finalize(() => {
  //       this.totalRequests--;
  //       if (this.totalRequests == 0) {
  //         this.loadingService.setLoading(false);
  //       }
  //     })
  //   );
  // }
}