import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {DropDownModel } from '../models/drop-down.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {
  constructor(private data: DataService) { }

  private filterDeleted(data?: DropDownModel[], idToInclude?: number): DropDownModel[] {
    if (!data || !data.length) {
      return data;
    }

    const filteredData = data.filter((t) => t.id === idToInclude || !t.isDeleted);
    return filteredData;
  }

  private getData(url: string, idToInclude?: any): Observable<DropDownModel[]> {
    return this.data.get<DropDownModel[]>(url).pipe(map((data) => this.filterDeleted(data, idToInclude)));
  }
  getBankNameDropDown(idToInclude?: any) {
    return this.getData(environment.baseUrl +'Bank/GetBankDropDown', idToInclude);
  }
  getappscreenDropDown() : Observable<any> {
    return this.getData(environment.baseUrl +`AppScreen/GetSubMenus`);
  }
  getAllAppScreenDropDown()  : Observable<any> {
    return this.getData(environment.baseUrl +`AppScreen/GetAllAppScreenDropDown`);
  }
  getAllTimezonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`common/timezones`);
  }
  getTimezonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`groups`);
  }
  getAllzonesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`zones`);
  }
  getAllRolesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`role`);
  } 
  getAllGatewayTypeDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`payment/gateway-type`);
  }
  getAllCheckListTypesDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`checklist/checklisttypes`);
  }
  getAllDurationsDropDown(): Observable<DropDownModel[]> {
    return this.getData(environment.baseUrl +`pricingPackages/durations`);
  }
}
