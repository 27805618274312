import { Component, OnInit } from '@angular/core';
import { SidebarService } from './../sidebar/sidebar.service';
import { HeadermenuService } from './../sidebar/headermenu.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionHandler } from 'src/app/auth/handlers/permission.handler';
import { Permissions } from 'src/app/auth/consts/permissions';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  currentMenuType: string;
  activeMenu: string = 'homepage';
  permissions = Permissions;
  routerLinkSettings: string;

  constructor(public sidebarservice: SidebarService
    , public permissionHandler: PermissionHandler, public HeadermenuService: HeadermenuService, private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.onMenuItemSelect(this.currentMenuType);
    });
  }
  getSideBarSate() {
    return this.sidebarservice.getSidebarState();
  }
  ngOnInit() {
    this.checkPermissionsRouter();
  }
  menuItems: string[] = [];
  headerMenu: string[] = []; // assuming this is populated elsewhere

  onMenuItemSelect(menuType: any) {
    this.activeMenu = menuType;
    this.currentMenuType = menuType;

    let menuItems = [];
    switch (menuType) {
      case 'homepage':
        menuItems = [];
        this.HeadermenuService.updateHeaderMenu(menuItems);
        break;

      case 'Analyticsclick':
        this.translate.get('MENU.ANALYTICS').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'permitclick':
        this.translate.get('MENU.PERMIT').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'violationclick':
        this.translate.get('MENU.VIOLATION').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'Noticeclick':
        this.translate.get('MENU.NOTICE').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;
      case 'settingclick':
        this.translate.get('MENU.SETTING').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;
      default:
        menuItems = [];
    }
  }

  checkPermissionsRouter(): any {
    let zonePermissions = this.permissionHandler.checkPermission([this.permissions.zone.View]);
    let groupPermissions = this.permissionHandler.checkPermission([this.permissions.group.View]);
    let locationPermissions = this.permissionHandler.checkPermission([this.permissions.location.View]);

    let userPermissions = this.permissionHandler.checkPermission([this.permissions.roles.View])
    let rolesPermissions = this.permissionHandler.checkPermission([this.permissions.users.View]);

    let vehicleColorsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_colors.View]);
    let vehicleMakesPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_makes.View]);
    let vehicleModelsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_models.View]);
    let vehicleLicencePlatePermissions = this.permissionHandler.checkPermission([this.permissions.license_plate.View]);

    let classificationPermissions = this.permissionHandler.checkPermission([this.permissions.classification.View]);
    let documentCategoryPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);
    let checklistsPermissions = this.permissionHandler.checkPermission([this.permissions.checklist.View]);
    let pricingpackagePermissions = this.permissionHandler.checkPermission([this.permissions.pricing_package.View]);
 
    let isAllPermission  = groupPermissions && zonePermissions && locationPermissions;
    if (isAllPermission) {
      this.routerLinkSettings = "components/setting/zone";
    }
    else if (groupPermissions) {
      this.routerLinkSettings = "components/setting/group";
    }
    else if (zonePermissions) {
      this.routerLinkSettings = "components/setting/location";
    } 
    else if (rolesPermissions) {
      this.routerLinkSettings = "components/setting/roles";
    }
    else if (userPermissions) {
      this.routerLinkSettings = "components/setting/users";
    }
    else if (vehicleColorsPermissions) {
      this.routerLinkSettings = "components/setting/vehicle-colors";
    }
    else if (vehicleMakesPermissions) {
      this.routerLinkSettings = "components/setting/vehicle-makes";
    }  
    else if (vehicleModelsPermissions) {
      this.routerLinkSettings = "components/setting/vehicle-models";
    } 
    else if (vehicleLicencePlatePermissions) {
      this.routerLinkSettings = "components/setting/license-plate";
    }
    else if (classificationPermissions) {
      this.routerLinkSettings = "components/setting/classification";
    }
    else if (documentCategoryPermissions) {
      this.routerLinkSettings = "components/setting/document-category";
    }
    else if (checklistsPermissions) {
      this.routerLinkSettings = "components/setting/checklist";
    }
    else if (pricingpackagePermissions) {
      this.routerLinkSettings = "components/setting/pricing-package";
    }
    

  }
}
