import { Component, OnInit } from '@angular/core';
import { SidebarService } from './../sidebar/sidebar.service'
import { HeadermenuService } from './../sidebar/headermenu.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { PermissionHandler } from 'src/app/auth/handlers/permission.handler';
import { Permissions } from 'src/app/auth/consts/permissions';
import { CurrentUserModel } from 'src/app/core/models/current-user';
import { UserService } from 'src/app/components/setting/users/users.service';
import { UserModel } from 'src/app/components/setting/users/users.model';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [UtilityService],
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  superadminbtn: boolean = false
  permissions = Permissions;
  isPermissionLocation=false;
  currentUser: CurrentUserModel;
  currentUserName:string;
  currentUserEmail:String;
  languageList: any = [
    { code: 'en', name: "English", img: 'assets/images/county/01.png'},
    { code: 'fr', name: "French", img: 'assets/images/county/03.png' },
    { code: 'es', name: "Spanish", img: 'assets/images/county/06.png' },
    { code: 'he', name: "Hindi", img: 'assets/images/county/08.png' }
  ]
  languageflg: any = 'assets/images/county/01.png';

  // new code for menu bar
  headerMap: any[] = [
    {
      header: 'Home',      
      isParent: true, 
      subheaders: [
        {header: 'Home',
          permissions: ['Home.dashboard-home.view'],
           routerLink: '/components/permit/dashboard',subheaders: []},
      ]
    },
    {
      header: 'Permits',
      isParent: true, // Indicating this is a parent header
      subheaders: [
        { 
          header: 'Dashboard',
           permissions: ['Permit.dashboard-permit.view'],
           routerLink: '/components/permit/dashboard',subheaders: []},
          { header: 'Dashboard',
           permissions: ['Permit.permit-user-dashboard.view'],
           routerLink: '/components/dashboard',subheaders: []},
        { header: 'Permit',
          permissions: ['Permit.book-permit.view','Permit.facilities.view'],
          subheaders: [
         // { header: 'Insight', permissions: [], routerLink: '/components/permit/insight' },
          { header: 'Permit Booking', permissions: ['Permit.book-permit.view'], routerLink: '/components/book-permit' },
         // { header: 'Manual Validation', permissions: [], routerLink: '/components/permit/manual-validation' },
          { header: 'Facilities', permissions: ['Permit.facilities.view'], routerLink: '/components/permit/facilities' },
        ]},
        { header: 'Setting',
          permissions: ['Permit.permit-Type.view','Permit.document-category.view','Permit.checklist.view','Permit.pricing-package.view',
            'Permit.cancellation-policy.view','Permit.accepted-document.view','Permit.faq-category.view','Permit.faq.view','Permit.terms-conditions.view'
          ],
          subheaders: [
          { header: 'Permit Type', permissions: ['Permit.permit-Type.view'], routerLink: '/components/permit/permit-type' },
          { header: 'Document Category', permissions: ['Permit.document-category.view'], routerLink: '/components/setting/document-category' },
          { header: 'Checklists', permissions: ['Permit.checklist.view'], routerLink: '/components/setting/checklist' },
          { header: 'Pricing Package', permissions: ['Permit.pricing-package.view'], routerLink: '/components/setting/pricing-package' },
          { header: 'Refund/Cancellation Policy', permissions: ['Permit.cancellation-policy.view'], routerLink: '/components/setting/cancellation-policy' },
          { header: 'Accepted Document', permissions: ['Permit.accepted-document.view'], routerLink: '/components/setting/accepted-document' },
          { header: 'FAQ Category', permissions: ['Permit.faq-category.view'], routerLink: '/components/setting/faq-category' },
          { header: 'FAQ', permissions: ['Permit.faq.view'], routerLink: '/components/setting/faq' },
          { header: 'Terms', permissions: ['Permit.terms-conditions.view'], routerLink: '/components/setting/terms-conditions' }
          ]
        }
      ]
    },
    {
      header: 'Violations',
      isParent: true, // Indicating this is a parent header
      subheaders: [
        { header: 'Dashboard',
          permissions: ['Enforcement.dashboard-enforcement.view'],
          routerLink: '/components/violation/dashboard',subheaders: []},
        // { header: 'Search',routerLink: '/components/violation/search',permissions: [], subheaders: [] },
        { header: 'Violation',
          permissions: ['Enforcement.violation-Management.view','Enforcement.violation-Management-add.view'], subheaders: [
          { header: 'Manage', permissions: ['Enforcement.violation-Management.view'], routerLink: '/components/violation/manage' },
          { header: 'Add', permissions: ['Enforcement.violation-Management-add.add'], routerLink: '/components/violation/add-violation' }
        ]  },
        // { header: 'Vehicle Activity', subheaders: [
        //   { header: 'Insight', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/vehicleactivity/insight' }
        //   ]
        // },
        // { header: 'Payment', subheaders: [
        //   { header: 'Insight', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/payment/insight' },
        //   { header: 'Pay Violation', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/payment/pay-violation' },
        //   { header: 'Import Payments', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/payment/import-payments' }
        // ] },
        { header: 'Configuration',
          permissions: ['Enforcement.location-configuration.view','Enforcement.violation-configuration.view','Enforcement.violation-Connections.view'],
          subheaders: [
          { header: 'Locations', permissions: ['Enforcement.location-configuration.view'], routerLink: '/components/configuration/location' },
          { header: 'Violation', permissions: ['Enforcement.violation-configuration.view'], routerLink: '/components/configuration/violation' },
          { header: 'Connections', permissions: ['Enforcement.violation-Connections.view'], routerLink: '/components/configuration/connection' },
          // { header: 'Payments', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/configuration/payment' },
          // { header: 'Notification', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/configuration/notification' },
          // { header: 'Appeals', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/configuration/appeal-configuration' }
        ] }
      ]
    },
    {
      header: 'Notices',
      isParent: true, // Indicating this is a parent header
      subheaders: [
        { header: 'Dashboard',routerLink: '/components/notice/dashboard',subheaders: []},
        { header: 'Insight',routerLink: '/components/notice/insight', subheaders: [] },
        { header: 'Collection Engine',routerLink: '/components/notice/collection-engine', subheaders: [] }
      ]
    },
    {
      header: 'Analytics',
      isParent: true, // Indicating this is a parent header
      subheaders: [
        { header: 'Permit',
          subheaders: [
            { header: 'API Configuration Report', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/permits' }
          ]
        },
        { header: 'Violation',
          subheaders: [
          { header: 'Violation Reports', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/report' },
          { header: 'Violations Due Summary', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/due-summary' },
          { header: 'Violations by Patroller', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/patroller' },
          { header: 'Violation Audit History', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/audit-history' },
          { header: 'Collections', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/collection' }
          ]
        },
        { header: 'Payment', subheaders: [
          { header: 'User Payment Tracking', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/user-payment-tracking' },
          { header: 'Payments Reconciliation', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/analytics/payments-reconciliation' }
      ] }
      ]
    },
    {
      header: 'Setting',
      isParent: true, // Indicating this is a parent header
      subheaders: [
        { header: 'Location Management',
          permissions: ['GeneralSetting.zone.view','GeneralSetting.group.view','GeneralSetting.location.view'],
          subheaders: [
            { header: 'Zone', permissions: ['GeneralSetting.zone.view'], routerLink: '/components/setting/zone' },
            { header: 'Group', permissions: ['GeneralSetting.group.view'] , routerLink: '/components/setting/group'},
            { header: 'Locations', permissions: ['GeneralSetting.location.view'], routerLink: '/components/setting/location' }
          ]
        },
        {
          header: 'Users',
          permissions: ['GeneralSetting.roles.view','GeneralSetting.users.view','GeneralSetting.classification.view'],
          subheaders: [
            { header: 'Roles', permissions: ['GeneralSetting.roles.view'], routerLink: '/components/setting/roles' },
            { header: 'Users', permissions: ['GeneralSetting.users.view'] , routerLink: '/components/setting/users'},
            // { header: 'Classification', permissions: ['GeneralSetting.classification.view'] , routerLink: '/components/setting/classification'}
          ]
        },
        { header: 'Vehicles',
          permissions: ['GeneralSetting.vehicle-colors.view','GeneralSetting.vehicle-makes.view','GeneralSetting.vehicle-models.view','GeneralSetting.license-plate.view'],
          subheaders:[
              { header: 'Colors', permissions: ['GeneralSetting.vehicle-colors.view'], routerLink: '/components/setting/vehicle-colors' },
              { header: 'Makes', permissions: ['GeneralSetting.vehicle-makes.view'], routerLink: '/components/setting/vehicle-makes' },
              { header: 'Models', permissions: ['GeneralSetting.vehicle-models.view'], routerLink: '/components/setting/vehicle-models' },
              { header: 'LicencePlate', permissions: ['GeneralSetting.license-plate.view'], routerLink: '/components/setting/license-plate' }
         ]
        },
        { header: 'Payments',
          permissions: ['GeneralSetting.payment-gateway.view'],
          subheaders: [
          { header: 'Payment Gateway', permissions: ['GeneralSetting.payment-gateway.view'], routerLink: '/components/setting/payment' }
          ]
        }
      ]
    }
  ];

  // subheaders: ['Manage', 'Add']
  activeHeader: string;
  headerSubheaderItems: any;
  activeSubheaderItems: any[];
  selectedSubheader: string;
  // loggedUserInfo: CurrentUserModel;
  loggedUserInfo: any;
  userProfile: UserModel;
  // end code

  constructor(private utils: UtilityService
    , private cdr: ChangeDetectorRef
    , public sidebarservice: SidebarService
    , public HeadermenuService: HeadermenuService
    , private router: Router
    , private translateService: TranslateService
    , public permissionHandler: PermissionHandler,
    private userService: UserService
  ) {
    this.loggedUserInfo = this.utils.storage.CurrentUser;
     this.sidebarservice.currentSidebarSelection$.subscribe(selectedHeader => {
      if (selectedHeader) {
        this.selectSidebarItem(selectedHeader);
      }
    });
  }
  ngOnInit() {
    this.ProfileDetail()
    this.currentUser = this.utils.storage.CurrentUser;
    this.utils.storage.setUserDataWithRole(this.currentUser);
    this.currentUserName = this.currentUser.fullName;
    this.currentUserEmail=this.currentUser.email;
    const storedHeaderItem = localStorage.getItem('activeHeaderItem');
    const storedSubheader = localStorage.getItem('activeSubheader');
    if (storedHeaderItem) {
      this.selectHeaderItem(storedHeaderItem);
    }
    if (storedSubheader) {
      this.selectedSubheader = storedSubheader;
    }
  }

  ProfileDetail(){
    this.userService.getById(this.loggedUserInfo.userid).subscribe((data) => {
      this.userProfile = data
      
    })
  }

  onSubheaderClick(subheaderItem: any): void {
    if (!subheaderItem.routerLink) {
      console.log('No router link defined for:', subheaderItem.header);
      return; // Prevent navigation
    }

    // Add any additional logic here if needed
  }


// new code
isSubheaderActive(header:string,subheaderItem: string): boolean {
  return this.router.url === this.getRouterLinkForSubheader(header);
}

getRouterLinkForSubheader(subheaderItem: any): string | null {
  return subheaderItem.routerLink || null;
}

filterHeadersByPermission(headers: any[]): any[] {
  return headers
    .map(header => {
      // Clone the header to avoid mutating the original
      const filteredHeader = { ...header };

      // Check if subheaders exist and filter them recursively
      if (filteredHeader.subheaders && filteredHeader.subheaders.length > 0) {
        filteredHeader.subheaders = this.filterHeadersByPermission(filteredHeader.subheaders);
      }

      // Check if the header itself has permissions or if it has subheaders with permissions
      const hasPermission =
        !filteredHeader.permissions || this.hasPermission(filteredHeader.permissions);

      // Only include the header if it has permissions or valid subheaders
      if (hasPermission || (filteredHeader.subheaders && filteredHeader.subheaders.length > 0)) {
        return filteredHeader;
      }

      // Exclude the header if no permissions match and it has no valid subheaders
      return null;
    })
    .filter(header => header !== null); // Remove null values
}

selectHeaderItem(header: string): void {
   const filteredHeaders = this.filterHeadersByPermission(this.headerSubheaderItems);
  const selectedItem = filteredHeaders.find(item => item.header === header);

  if (selectedItem) {
    if (selectedItem.subheaders?.length) {
       this.activeSubheaderItems = selectedItem.subheaders.filter(subheader =>
        this.hasPermission(subheader.permissions)
      );

      const storedSubheader = localStorage.getItem('activeSubheader');
      const validSubheader = this.activeSubheaderItems.find(
        subheader => subheader.header === storedSubheader
      );

      this.selectedSubheader = validSubheader
        ? validSubheader.header
        : this.activeSubheaderItems[0]?.header || null;
    } else {
      this.activeSubheaderItems = [];
      this.selectedSubheader = null;
    }

    localStorage.setItem('activeHeaderItem', header);
    if (this.selectedSubheader) {
      localStorage.setItem('activeSubheader', this.selectedSubheader);
    }
  } else {
    // If the header is not valid (e.g., no permissions), reset active items
    this.activeSubheaderItems = [];
    this.selectedSubheader = null;
    localStorage.removeItem('activeHeaderItem');
    localStorage.removeItem('activeSubheader');
  }
}


// selectHeaderItem(header: string) {
//   debugger
//   // Use findHeaderItem to search for the header (this allows for nested subheaders)
//   const selectedItem = this.findHeaderItem(header, this.headerSubheaderItems);

//   if (selectedItem && Array.isArray(selectedItem.subheaders)) {
//     // Filter subheaders based on permissions
//     this.activeSubheaderItems = selectedItem.subheaders.filter(subheader =>
//       this.hasPermission(subheader.permissions)
//     );

//     // Restore active subheader from localStorage if valid
//     const storedSubheader = localStorage.getItem('activeSubheader');
//     const validSubheader = this.activeSubheaderItems.find(
//       subheader => subheader.header === storedSubheader
//     );

//     this.selectedSubheader = validSubheader ? validSubheader.header : this.activeSubheaderItems[0]?.header || null;
//   } else {
//     this.activeSubheaderItems = [];
//     this.selectedSubheader = null;
//   }

//   // Save selected header to localStorage
//   localStorage.setItem('activeHeaderItem', header);

//   // Save selected subheader to localStorage
//   if (this.selectedSubheader) {
//     localStorage.setItem('activeSubheader', this.selectedSubheader);
//   }
// }

// Recursive function to find the header item (parent or child)
findHeaderItem(header: string, items: any[]): any {
  for (const item of items) {
    if (item.header === header) {
      return item; // Return the item if the header matches
    }
    // Recursively check for the header in nested subheaders
    if (item.subheaders?.length) {
      const found = this.findHeaderItem(header, item.subheaders);
      if (found) {
        return found; // Return the found item from nested subheaders
      }
      console.log(found);
    }
  }
  return null; // Return null if no match is found
}

// selectHeaderItem(header: string) {
//   debugger;
//   const selectedItem = this.headerSubheaderItems.find(item => item.header === header);

//   if (selectedItem && Array.isArray(selectedItem.subheaders)) {
//     // Filter subheaders based on permissions
//     this.activeSubheaderItems = selectedItem.subheaders.filter(subheader =>
//       this.hasPermission(subheader.permissions)
//     );

//     // Restore active subheader from localStorage if valid
//     const storedSubheader = localStorage.getItem('activeSubheader');
//     const validSubheader = this.activeSubheaderItems.find(
//       subheader => subheader.header === storedSubheader
//     );
//     this.selectedSubheader = validSubheader ? validSubheader.header : null;
//   } else {
//     this.activeSubheaderItems = [];
//     this.selectedSubheader = null;
//   }

//   // Save selected header to localStorage
//   localStorage.setItem('activeHeaderItem', header);
// }

selectSidebarItem(selectedSidebarItem: string) {

  // Step 1: Find the exact header where `isParent` is true and matches the selected item
  const selectedParentItem = this.headerMap.find(
    item => item.header === selectedSidebarItem && item.isParent
  );

  if (selectedParentItem) {
    // Step 2: Set the active header to the selected parent
    this.activeHeader = selectedSidebarItem;

    // Step 3: Handle subheaders for the selected parent
    if (Array.isArray(selectedParentItem.subheaders) && selectedParentItem.subheaders.length > 0) {
      this.headerSubheaderItems = selectedParentItem.subheaders; // Set subheaders
    } else {
      this.headerSubheaderItems = [selectedParentItem]; // No subheaders; use parent as a standalone
    }

    // Reset active subheader items
    this.activeSubheaderItems = [];
  } else {
    // Check if it matches a subheader (edge case)
    const subheaderMatch = this.headerMap.find(parent =>
      parent.subheaders?.some(sub => sub.header === selectedSidebarItem)
    );

    if (subheaderMatch) {
      // Activate the parent of the subheader
      this.activeHeader = subheaderMatch.header;
      this.headerSubheaderItems = subheaderMatch.subheaders;

      // Set the clicked subheader as active
      this.activeSubheaderItems = subheaderMatch.subheaders.filter(sub => sub.header === selectedSidebarItem);
    } else {
      console.warn(`Sidebar item "${selectedSidebarItem}" does not match any headers or subheaders.`);
    }
  }

  // Save the active header to localStorage for persistence
  localStorage.setItem('activeHeader', this.activeHeader);
}

  selectSidebarItem1(selectedHeader: string) {
    this.activeHeader = selectedHeader;
    const selectedItem = this.headerMap.find(item => item.header === selectedHeader);
    if (selectedItem) {
      this.headerSubheaderItems = Array.isArray(selectedItem.subheaders)
        ? selectedItem.subheaders
        : [selectedItem]; // Set headers for Main Report or list of locations
      this.activeSubheaderItems = []; // Reset active subheader items
    }
    localStorage.setItem('activeHeader', selectedHeader); // Save header state
  }


  hidenavigation: boolean = true
  showsuperadmin() {
    this.superadminbtn = true
    this.router.navigate(['super-admin/rokerplus']);
    if (this.superadminbtn) {
      this.hidenavigation = true;
    }
  }

  isHeaderActive(header: string): any {
    return header === localStorage.getItem('activeHeaderItem');
  }

  logoutbtn() {
    this.utils.storage.clear();
    this.router.navigate(['/auth/sign-in'])
  }
  paymentpage() {
    this.router.navigate(['/components/public-portal/payment'])
    this.hidenavigation = true
    this.superadminbtn = false
  }


  theme_name = 'dark_mode'

  toggleSearch: boolean = false;

  darkMode() {

    if (this.theme_name == 'light_mode') {
      document.querySelector("html").classList.replace('dark_mode', 'light_mode');
      this.theme_name = 'dark_mode'

    } else if (this.theme_name == 'dark_mode') {
      document.querySelector("html").classList.replace('light_mode', 'dark_mode');
      this.theme_name = 'light_mode'

    }
    return this.theme_name;
  }

  getSideBarSate() {
    return this.sidebarservice.getSidebarState();
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  openSearch() {
    this.toggleSearch = true;
  }

  searchClose() {
    this.toggleSearch = false;
  }
  selectLanguage(lan: any) {
    this.languageflg = lan.img;
    this.translateService.use(lan.code).subscribe(() => {
      this.cdr.detectChanges();
    });
  }


  hasPermission(permissions: string[]): boolean {
    if (!permissions || permissions.length === 0) {
      return true; // Allow if no specific permission is required
    }
    return this.permissionHandler.checkPermission(permissions);
  }

  checkPermission(requiredPermissions: string[]): boolean {
    // Replace with the actual logic to retrieve user permissions
    const userPermissions = [
      //permit
      this.permissions.book_permit.View,
      this.permissions.Facilities.View,

      //permit settings
      this.permissions.classification.View,
      this.permissions.document_category.View,
      this.permissions.checklist.View,
      this.permissions.pricing_package.View,
      this.permissions.cancellation_policy.View,

      this.permissions.accepted_document.View,
      this.permissions.faq_category.View,
      this.permissions.faq.View,
      this.permissions.terms_conditons.View,

      //settings
      this.permissions.zone.View,
      this.permissions.group.View,
      this.permissions.location.View,

      this.permissions.roles.View,
      this.permissions.users.View,

      this.permissions.vehicle_colors.View,
      this.permissions.vehicle_models.View,
      this.permissions.vehicle_makes.View,
      this.permissions.license_plate.View,

      this.permissions.Payment_gateways.View,

      this.permissions.Violation_Configuration_Numbering.View
    ];

    // Check if any of the required permissions are available
    return requiredPermissions.some(permission => userPermissions.includes(permission));
  }

}