import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HeadermenuService {
  private headerMenuSource = new BehaviorSubject<string[]>([]);
  headerMenu$ = this.headerMenuSource.asObservable();

  // Method to update the header menu items
  updateHeaderMenu(menuItems: string[]) {
    this.headerMenuSource.next(menuItems);
  }
  constructor() { }
}
