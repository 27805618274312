export const environment = {
  environment: 'development',
 // baseUrl: 'https://localhost:5288/',
 baseUrl: 'https://api.easyparking.co.in/',  
  //permitApiBaseUrl:'https://localhost:5289/',
  permitApiBaseUrl :'https://permit.easyparking.co.in/',
  enforcementApiBaseUrl: 'http://connect.easyparking.co.in/', 
  violationpayweburl:'https://{identifier}.develop.rokerplus.com/violation-pay',
  hpsPaymentPublicKey: 'pkapi_cert_ZfQnTCQkHOrvUhvC62'
};
