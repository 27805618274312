import { Injectable } from '@angular/core';
import { Constant } from '../constants/constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private toastr: ToastrService) { }

  private removeAuditReason() {
    localStorage.removeItem('audit-reason-id');
    localStorage.removeItem('audit-reason-oth');
  }

  recordDeleted(pageName: string): void {
    this.removeAuditReason();
    this.success(pageName + ' ' + Constant.message.deleteRecord);
  }
  recordActivated(pageName: string): void {
    this.removeAuditReason();
    this.success(pageName + ' ' + Constant.message.ActivatedRecord);
  }
  recordSaved(pageName: string): void {
    this.removeAuditReason();
    this.success(pageName + ' ' + Constant.message.saveRecord);
  }
  recordUpdate(pageName: string): void {
    this.removeAuditReason();
    this.success(pageName + ' ' + Constant.message.updateRecord);
  }
  success(message: string): void {
    this.toastr.success(message);
  }
  info(message: string): void {
    this.toastr.info(message);
  }
  warning(message: string): void {
    this.toastr.warning(message);
  }
  error(message: string, title?: string): void {
    this.toastr.error(message);
  }
}
