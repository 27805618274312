import { Injectable } from '@angular/core';
import { PermissionsModel } from 'src/app/core/models/drop-down.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionHandler {
  constructor() { }
  checkPermissionWithBulk(permissions: string[], permissionsModel: PermissionsModel): PermissionsModel {
    // Read assigned permissions from localStorage
    const assignedPermissionsJSON = localStorage.getItem('assignedPermissions');

    // Parse assigned permissions from JSON
    const assignedPermissions: string[] = assignedPermissionsJSON
      ? JSON.parse(assignedPermissionsJSON)
      : [];

    // Loop through each permission and update the permissionsModel based on the permission
    permissions.forEach(permissions => {
      const permission = permissions.toLowerCase(); 
      if (assignedPermissions.some(assignedPermission => assignedPermission.toLowerCase() === permission)) {
        if (permission.includes('view')) {
          permissionsModel.View = true;
        }
        if (permission.includes('add')) {
          permissionsModel.Add = true;
        }
        if (permission.includes('edit')) {
          permissionsModel.Edit = true;
        }
        if (permission.includes('delete')) {
          permissionsModel.Delete = true;
        }
      }
    });
    return permissionsModel;
  }
  checkPermission(permissions: string[]): boolean {
    const assignedPermissionsJSON = localStorage.getItem('assignedPermissions');

    // Parse assigned permissions from JSON
    const assignedPermissions: string[] = assignedPermissionsJSON
      ? JSON.parse(assignedPermissionsJSON)
      : [];

    // Check if any of the required permissions exist in the assigned permissions
    return permissions.some((permission) =>
      assignedPermissions.includes(permission)
    );
  }

  getPermissionRoute(permissions: any) {
    // Read assigned permissions from localStorage
    const assignedPermissionsJSON = localStorage.getItem('assignedPermissions');

    // Parse assigned permissions from JSON
    const assignedPermissions: string[] = assignedPermissionsJSON
      ? JSON.parse(assignedPermissionsJSON)
      : [];

    const permissionArr: { isPermit: boolean; url: any; }[] = [];

    // Check if any of the required permissions exist in the assigned permissions
    permissions.map((permission: { permission: string; url: any; }) => {
      const obj = {
        isPermit: assignedPermissions.includes(permission?.permission),
        url: permission?.url
      }
      permissionArr.push(obj);
    });
    const filteredArr = permissionArr.filter((obj) => obj.isPermit);
    return filteredArr;
  }
}
