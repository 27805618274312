export const Permissions = { 
  group: {
    View: 'GeneralSetting.group.view',
    Add: 'GeneralSetting.group.Add',
    Edit: 'GeneralSetting.group.edit',
    Delete: 'GeneralSetting.group.delete',
  },
  zone: {
    View: 'GeneralSetting.zone.view',
    Add: 'GeneralSetting.zone.add',
    Edit: 'GeneralSetting.zone.edit',
    Delete: 'GeneralSetting.zone.delete',
  },
  location: {
    View: 'GeneralSetting.location.view',
    Add: 'GeneralSetting.location.add',
    Edit: 'GeneralSetting.location.edit',
    Delete: 'GeneralSetting.location.delete',
  },
  vehicle_makes: {
    View: 'GeneralSetting.vehicle-makes.view',
    Add: 'GeneralSetting.vehicle-makes.add',
    Edit: 'GeneralSetting.vehicle-makes.edit',
    Delete: 'GeneralSetting.vehicle-makes.delete',
  },
  vehicle_colors: {
    View: 'GeneralSetting.vehicle-colors.view',
    Add: 'GeneralSetting.vehicle-colors.add',
    Edit: 'GeneralSetting.vehicle-colors.edit',
    Delete: 'GeneralSetting.vehicle-colors.delete',
  },
  vehicle_models: {
    View: 'GeneralSetting.vehicle-models.view',
    Add: 'GeneralSetting.vehicle-models.add',
    Edit: 'GeneralSetting.vehicle-models.edit',
    Delete: 'GeneralSetting.vehicle-models.delete',
  },
  license_plate: {
    View: 'GeneralSetting.license-plate.view',
    Add: 'GeneralSetting.license-plate.add',
    Edit: 'GeneralSetting.license-plate.edit',
    Delete: 'GeneralSetting.license-plate.delete',
  },
  roles: {
    View: 'GeneralSetting.roles.view',
    Add: 'GeneralSetting.roles.add',
    Edit: 'GeneralSetting.roles.edit',
    Delete: 'GeneralSetting.roles.delete',
  },
  users: {
    View: 'GeneralSetting.users.view',
    Add: 'GeneralSetting.users.add',
    Edit: 'GeneralSetting.users.edit',
    Delete: 'GeneralSetting.users.delete',
  },
  Payment_gateways: {
    View: 'GeneralSetting.payment-gateways.view',
    Add: 'GeneralSetting.payment-gateways.add',
    Edit: 'GeneralSetting.payment-gateways.edit',
    Delete: 'GeneralSetting.payment-gateways.delete',
  },
  document_category: {
    View: 'GeneralSetting.document-category.view',
    Add: 'GeneralSetting.document-category.add',
    Edit: 'GeneralSetting.document-category.edit',
    Delete: 'GeneralSetting.document-category.delete',
  },
  classification :{
    View: 'GeneralSetting.classification.view',
    Add: 'GeneralSetting.classification.add',
    Edit: 'GeneralSetting.classification.edit',
    Delete: 'GeneralSetting.classification.delete',
  },
  checklist:{
    View: 'GeneralSetting.checklist.view',
    Add: 'GeneralSetting.checklist.add',
    Edit: 'GeneralSetting.checklist.edit',
    Delete: 'GeneralSetting.checklist.delete',
  },
  pricing_package :{
    View: 'GeneralSetting.pricing-package.view',
    Add: 'GeneralSetting.pricing-package.add',
    Edit: 'GeneralSetting.pricing-package.edit',
    Delete: 'GeneralSetting.pricing-package.delete',
  }
};
